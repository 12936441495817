module.exports = {
  "/id/:videoId": {
    "success": {
      "player loaded": [
        "PLAYER_LOADED"
      ],
      "video not found": [
        "VIDEO_NOT_FOUND"
      ],
      "video not authorized": [
        "VIDEO_NOT_AUTHORIZED"
      ],
      "video fetch aborted": [
        "VIDEO_FETCH_ABORTED"
      ],
      "crawler error": [
        "CRAWLER_ERROR"
      ]
    },
    "error": [
      "GENERIC_PLAYER_ERROR",
      "VIDEO_FETCH_TIMEOUT",
      "VIDEO_FETCH_FAILED_OTHER",
      "VIDEOJS_LOAD_ERROR",
      "VIDEOJS_PLAYBACK_ERROR"
    ]
  }
};