import { isIframed, shouldIgnoreUserAgent } from 'video-embed/utils/env';
import { Metrics } from './utils/metrics';
import startApp from './start';
window._hsq = window._hsq || [];
if (isIframed()) {
  document.body.classList.add('player-iframed');
} else {
  document.body.classList.add('player-standalone');
}
if (window.location.search.includes('maxHeight=')) {
  document.body.classList.add('with-max-height');
}
if (shouldIgnoreUserAgent()) {
  document.body.classList.add('player-unsupported');
  Metrics.counter('ignored-unsupported-useragent').increment();
} else {
  window._app = startApp();
}