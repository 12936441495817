import PortalIdParser from 'PortalIdParser';
import memoize from 'react-utils/memoize';
import { makeUuid } from 'usage-tracker-core/common/helpers';
import { createTracker as createAuthedAppTracker } from 'usage-tracker';
import { createTracker as createAnonymousTracker } from 'usage-tracker-anonymous';
import { isAuthedAppPreview } from 'video-embed/utils/env';
import events from '../../events.yaml';
const lastKnownEventProperties = ['playerType', 'videoId', 'videoTitle'];
const IGNORE_USERAGENT_TOKENS = ['HubSpot Crawler'];
export const getSessionId = memoize(() => {
  return String(new Date().valueOf());
});
export const shouldIgnoreVisit = (navigator = window.navigator) => {
  return navigator.userAgent && IGNORE_USERAGENT_TOKENS.some(token => navigator.userAgent.includes(token));
};
export const getSecondsElapsed = () => window.performance.now() / 1000;
let tracker;
const createUsageTracker = properties => {
  if (tracker) {
    return tracker;
  }
  if (isAuthedAppPreview()) {
    tracker = createAuthedAppTracker({
      events,
      lastKnownEventProperties,
      properties
    });
  } else {
    tracker = createAnonymousTracker({
      events,
      bypassPool: true,
      // important - use sendBeacon API to capture attention videoAttentionSpan as window/frame unloads
      lastKnownEventProperties,
      properties: Object.assign({}, properties, {
        hubId: PortalIdParser.get(),
        // we use UUIDs deviceId for public tracking to ensure we don't affect contacts in portal 53
        // see https://product.hubteam.com/docs/usage-tracking-manual/docs/frontend/anonymous-tracking.html
        deviceId: makeUuid()
      })
    });
  }
  return tracker;
};
export default createUsageTracker;