import { $SessionCounter, MetricsFactory } from '../MetricsFactory';
import { getHubSpot } from '../getGlobal';
let evtTarget = window;
let factory;
function setErrorMetricsSetupComplete(newValue) {
  getHubSpot().__errorMetricsSetupComplete = newValue;
}
function getErrorMetricsSetupComplete() {
  return getHubSpot().__errorMetricsSetupComplete;
}
export function initErrorMetrics(customTarget) {
  // for testing - actually dispatching errors to the window during tests
  // causes the tests to fail
  evtTarget = customTarget || evtTarget;
  if (factory || getErrorMetricsSetupComplete()) {
    return;
  }

  // Only setup listeners once, otherwise we get multiple errors per asynchronously loaded bundle (Navigation, Zorse, etc).
  setErrorMetricsSetupComplete(true);

  // Need to construct a factory directly to avoid a circular dependency in
  // createMetricsFactory. Do not copy this into app/library code.
  // Final metric name: *.frontend.js.errors.count
  factory = new MetricsFactory('js', {});

  // We need to initialize per-session metrics to 0, otherwise the metric
  // will not be reported if no errors occur in a session.
  factory[$SessionCounter]('errors-per-session').increment(0);
  try {
    evtTarget.addEventListener('unhandledrejection', onUnhandledPromiseRejection);
    evtTarget.addEventListener('rejectionhandled', onHandledPromiseRejection);
    evtTarget.addEventListener('ravenSuccess', onRavenRequestSuccess);
    evtTarget.addEventListener('ravenCaptureIgnored', onRavenCaptureIgnored);
  } catch (__err) {
    // ignore, this is an unrecoverable failure
  }
}
export function getMetricsFactoryForTesting() {
  return factory;
}
export function resetErrorTrackingForTesting() {
  try {
    factory = undefined;
    if (getHubSpot()) delete getHubSpot().__errorMetricsSetupComplete;
  } catch (__err) {
    // ignore, this is an unrecoverable failure
  }
}
const EXTENSION_REGEX = /@<inline>|moz-extension:\/\/|chrome-extension:\/\/|safari-web-extension:\/\/|safari-extension:\/\//;
function isBrowserExtensionError(errObj) {
  if (errObj && errObj.stack && errObj.stack.match(EXTENSION_REGEX)) {
    if (factory) {
      factory.counter('browser-extension-errors').increment();
    }
    return true;
  }
  return false;
}
function onUnhandledPromiseRejection(evt) {
  if (!factory) return;
  if (evt.reason && isBrowserExtensionError(evt.reason)) {
    return;
  }
  factory.counter('unhandled-promise-rejection').increment();
}
function onHandledPromiseRejection() {
  if (!factory) return;
  factory.counter('handled-promise-rejection').increment();
}
function onRavenRequestSuccess(errEvt) {
  if (!factory) return;
  const data = 'data' in errEvt ? errEvt.data : undefined;

  // level may be undefined, that indicates a error-level report
  if (data && (data.level === 'error' || data.level == null)) {
    factory.counter('errors').increment();
    factory[$SessionCounter]('errors-per-session').increment();
  }
}
function onRavenCaptureIgnored(evt) {
  if (!factory) return;

  // level always defined in ravenCaptureIgnored event
  if ('level' in evt && evt.level === 'error') {
    factory.counter('error-reports-ignored-by-configuration').increment();
  }
}